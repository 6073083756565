/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import styles from '../websiteManagement.module.scss';
import placeholder from 'assets/icons/table-placeholder.svg';
import Button from 'components/Button/Button';
import Plus from 'assets/icons/Plus.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from 'components/Pagination/Pagination';
import PropTypes from 'prop-types';
import SearchBox from 'components/SearchBox/SearchBox';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { deleteWebCenterAction, getWebsiteCentersAction } from 're-ducks/centers';
import getTotalPages from 'helpers/getTotalPages';
import DeleteCenterModal from './deleteCenter';
import { toast } from 'react-toastify';
import SingleCenter from './singleCenter';
import SinglePageLoader from 'components/Loading/SinglePageLoader';
import FilterIcon from 'assets/icons/filterIcon.svg';
import Cancel from 'assets/icons/close.svg';
import FilterWebsiteCenter from './filterWebsiteCenter';

const EmptyState = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.EmptyState}>
      <img src={placeholder} alt="" />
      <p>No centre record yet.</p>
      <Button theme="primary" size="sm" onClick={() => navigate('/create-website-center')}>
        <img src={Plus} alt="add" />
        Create Website Centers
      </Button>
    </div>
  );
};
const WebsiteCenter = ({ setGetBranchCount }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(location?.state?.page || 1);
  const [getWebsiteCenters, setGetWebsiteCenters] = useState([]);
  const [total, setTotal] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [centerId, setCenterId] = useState();
  const [deleting, setDeleting] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [searchDebounce] = useDebounce(searchValue, 300);
  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const fetchWebsiteCenters = useCallback(() => {
    setLoading(true);
    dispatch(
      getWebsiteCentersAction(
        {
          params: {
            page: page,
            search: searchDebounce,
            per_page: 50,
            ...filteredData
          }
        },
        (res) => {
          setGetWebsiteCenters(res?.branches);
          setLoading(false);
          setTotal(res?.pageOptions?.total);
          setGetBranchCount(res?.pageOptions?.total);
        },
        () => {
          setLoading(false);
        }
      )
    );
  }, [dispatch, page, searchDebounce, setGetBranchCount, filteredData]);

  useEffect(() => {
    fetchWebsiteCenters();
  }, [page, searchDebounce, filteredData]);

  const handlePageChange = (num) => {
    setPage(num.selected + 1);
  };
  const handleDeleteModal = () => {
    setDeleting(true);
    dispatch(
      deleteWebCenterAction(
        centerId.id,
        () => {
          setDeleting(false);
          toast.success('deleted center successfully');
          setDeleteModal(false);
          fetchWebsiteCenters();
        },
        (err) => {
          setDeleting(false);
          setDeleteModal(false);
          toast.error(err);
        }
      )
    );
  };
  const CenterViewOption = ({ children, row }) => {
    return (
      <td
        row={row}
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/website-management/website-centers/details/${row.id}`)}
      >
        {children}
      </td>
    );
  };
  CenterViewOption.propTypes = {
    children: PropTypes.any,
    row: PropTypes.object
  };
  return (
    <section>
      <div className={styles.actionBox__search}>
        <SearchBox
          placeholder="Search by name"
          onChange={(e) => {
            setSearchValue(e);
            setPage(1);
          }}
          searchValue={searchValue}
        />
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
          <Button theme="secondary" size="plain">
            <div>
              {Object.keys(filteredData).length !== 0 ? (
                <div
                  className={styles.filterBtn}
                  aria-hidden
                  onClick={() => {
                    setFilteredData([]);
                    setOpenFilter(true);
                  }}
                >
                  <p>Filter</p> &nbsp;
                  <img src={Cancel} alt="cancel" />
                </div>
              ) : (
                <div className={styles.filterBtn} onClick={() => setOpenFilter(true)} aria-hidden>
                  <p>Filter</p> &nbsp;
                  <img src={FilterIcon} alt="filter" />
                </div>
              )}
            </div>
          </Button>
          <Button theme="primary" size="sm" onClick={() => navigate('/create-website-center')}>
            <img src={Plus} alt="add" />
            &nbsp;
            {'Create Website-center'}
          </Button>
        </div>
      </div>
      <div>
        {loading ? (
          <SinglePageLoader />
        ) : (
          <div className={styles.categories}>
            {getWebsiteCenters.map((item) => (
              <div>
                <SingleCenter
                  item={item}
                  handleDeleteCenter={() => {
                    setCenterId(item);
                    setDeleteModal(true);
                  }}
                />
              </div>
            ))}
          </div>
        )}
        {deleteModal && (
          <DeleteCenterModal
            isOpen={deleteModal}
            isClose={() => setDeleteModal(false)}
            onDelete={() => handleDeleteModal('delete')}
            loadingBtn={deleting}
          />
        )}
        {!loading && (
          <Pagination
            totalPage={getTotalPages(total, 50)}
            changeCurrentPage={handlePageChange}
            currentPage={page}
            handlePageInput={(e) => setPage(e)}
            forcePage={page - 1}
          />
        )}
        {openFilter && (
          <FilterWebsiteCenter
            isOpen={openFilter}
            isClose={() => setOpenFilter(false)}
            setFilteredData={setFilteredData}
            setPage={setPage}
          />
        )}
      </div>
    </section>
  );
};
WebsiteCenter.propTypes = {
  setGetBranchCount: PropTypes.func
};
export default WebsiteCenter;
