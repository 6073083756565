import * as yup from 'yup';

export const removeSpaceRegExp = /\s+/g;

const pattern =
  /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,30}$/;

const phoneRegExp = /[0-9]{1,11}/;

export const SignInSchema = yup.object().shape({
  email: yup.string().email().trim().required('email is required*'),
  password: yup
    .string()
    .required('password is required*')
    .min(8, 'Can not be less than eight characters')
    .max(30, 'Too Long')
    .matches(pattern, 'Must be mixed with special characters')
    .trim()
});

export const InviteUserSchema = yup.object().shape({
  email: yup.string().email().trim().required('email is required*'),
  first_name: yup.string().required('First name is required*'),
  last_name: yup.string().required('Last name is required*')
});

export const formSchema = yup.object().shape({
  name: yup.string().trim().required('Center name is a required field'),
  // address: yup.string().trim().required('Address is a required field'),
  email: yup.string().email().trim().required('email is a required field'),
  phone_number: yup
    .string()
    .trim()
    .matches(phoneRegExp, 'phone number is not valid')
    .min(11, 'Must be 11 characters')
    .max(11, 'Must be 11 characters'),
  city: yup.string().trim().required('city is a required field*'),
  referral_percentage: yup.string().trim().required('percentage is required')
  // .min(2, 'must not be more than two')
  // .matches(percentRegExp, 'must add %')
});

export const fullServiceSchema = yup.object().shape({
  name: yup.string().trim().required('Display name is a required field'),
  time_open_weekday: yup.string().trim().required('Opening time is a required field'),
  time_closed_weekday: yup.string().trim().required('Closed time is a required field'),
  time_open_weekend: yup.string().trim().required('Opening time is a required field'),
  time_closed_weekend: yup.string().trim().required('Closed time is a required field')
});
export const affiliateSchema = yup.object().shape({
  name: yup.string().trim().required('Center name is a required field'),
  address: yup.string().trim().required('Address is a required field'),
  contact_number: yup
    .string()
    .trim()
    .matches(phoneRegExp, 'phone number is not valid')
    .min(11, 'Must be 11 characters')
    .max(11, 'Must be 11 characters'),
  city: yup.string().trim().required('city is a required field'),
  time_open_weekday: yup.string().trim().required('Opening time is a required field'),
  time_closed_weekday: yup.string().trim().required('Closed time is a required field'),
  time_open_weekend: yup.string().trim().required('Opening time is a required field'),
  time_closed_weekend: yup.string().trim().required('Closed time is a required field')
});

export const bonusSchema = (bonus) =>
  yup.object().shape({
    bonus: yup
      .string()
      .trim()
      .required('bonus is a required field')
      .matches(bonus, 'bonus is not valid')
  });

export const partnerSchema = yup.object().shape({
  name: yup.string().trim().required("Partner's name is a required field"),
  contact_person: yup.string().trim().required('Contact person is a required field'),
  email: yup.string().email().trim().required('email is required*'),
  office_address: yup.string().trim().required('Address is a required field'),
  phone_number: yup
    .string()
    .trim()
    .required('phone number is required*')
    .matches(phoneRegExp, 'phone number is not valid')
    .min(11, 'Must be 11 characters')
    .max(11, 'Must be 11 characters'),
  city: yup.string().trim().required('city is a required field*')
});
export const ChangeProfileSchema = yup.object().shape({
  first_name: yup.string().required('First name is required*'),
  last_name: yup.string().required('Last name is required*'),
  phone_number: yup
    .string()
    .trim()
    .required('phone number is required*')
    .matches(phoneRegExp, 'phone number is not valid')
    .min(11, 'Must be 11 characters')
    .max(11, 'Must be 11 characters'),
  email: yup.string().email('Email must be valid').trim().required('Email is required*')
});

export const CreateCategoryNameSchema = yup.object().shape({
  name: yup.string().required('Category name is required*')
});

export const CreateCategoryFormSchema = yup.object().shape({
  name: yup.string().required('Category title is required*'),
  show_in: yup.string().required('Category Location is required'),
  description: yup.string().max(200, 'Description cannot exceed 200 characters.').trim()
});
