import React from 'react';
import Input from 'components/FormInput/FormInput';
import styles from '../../centerFormLayout/centerForm.module.scss';
import Select from 'components/Select';
const FormDetails = ({ formik, states, selectState, setSelectState }) => {
  return (
    <div>
      <div className={styles.mainform__input}>
        <Input
          label="Centre name"
          placeholder="Centre name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="name"
        />
        {formik.errors.name ? <span className="error">{formik.errors.name}</span> : null}
      </div>
      <div className={styles.mainform__input}>
        <Input
          label="Address"
          placeholder="Address"
          value={formik.values.address}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="address"
        />
        {formik.errors.address ? <span className="error">{formik.errors.address}</span> : null}
      </div>
      <div className={styles.mainform__top}>
        <div className={styles.mainform__input} style={{ width: '100%' }}>
          <Input
            label="Phone Number"
            type="text"
            placeholder="07000000000"
            value={formik.values.contact_number}
            onChange={formik.handleChange}
            name="contact_number"
          />
          {formik.errors.contact_number ? (
            <span className="error">{formik.errors.contact_number}</span>
          ) : null}
        </div>
      </div>

      <div className={styles.mainform__double}>
        <div className={styles.mainform__select}>
          <Select
            label="State"
            placeholder={formik.values.state ?? 'Select'}
            options={states.map((item) => ({
              name: item.name,
              id: item.code
            }))}
            selected={selectState}
            handleChange={(e) => {
              formik.setFieldValue('state', e.name);
              setSelectState(e);
            }}
          />
        </div>
        <div>
          <Input
            label="City"
            type="text"
            placeholder="City"
            name="city"
            value={formik.values.city}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.city ? <span className="error">{formik.errors.city}</span> : null}
        </div>
      </div>
    </div>
  );
};
FormDetails.propTypes;
export default FormDetails;
