import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styles from '../websiteManagement.module.scss';
import { fetchAllWebsiteCategoriesAction, orderCategoryAction } from 're-ducks/websiteManagement';
import EmptyState from '../emptyState';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Pagination from 'components/Pagination/Pagination';
import getTotalPages from 'helpers/getTotalPages';
import SearchBox from 'components/SearchBox/SearchBox';
import { useDebounce } from 'use-debounce';
import FilterIcon from 'assets/icons/filterIcon.svg';
import Button from 'components/Button/Button';
import Cancel from 'assets/icons/close.svg';
import FilterHomePackages from './filterHomePackages';
import { useNavigate } from 'react-router-dom';
import Plus from 'assets/icons/Plus.svg';
import ResponsiveHomePackages from './responsiveHomePackages';

const HomePagePackages = ({ setCount, page, setPage }) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [allCategories, setAllCategories] = useState({ packageCategories: [] });
  const [searchValue, setSearchValue] = useState('');
  const [searchDebounce] = useDebounce(searchValue, 300);
  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState({});
  const [updatedData, setUpdatedData] = useState();
  const [toggleOrderPackages, setToggleOrderPackages] = useState(false);

  const navigate = useNavigate();

  const handlePageChange = (num) => {
    setPage(num.selected + 1);
  };

  const fetchAllWebsiteCategories = useCallback(() => {
    setLoading(true);
    dispatch(
      fetchAllWebsiteCategoriesAction(
        {
          params: {
            ...filteredData,
            page: page,
            per_page: 50,
            search: searchDebounce
          }
        },
        (res) => {
          setLoading(false);
          setToggleOrderPackages(false);
          setUpdatedData([]);
          setAllCategories(res.data);
          setCount(res?.data?.total);
        },
        () => {
          setLoading(false);
        }
      )
    );
  }, [dispatch, filteredData, page, searchDebounce, setCount]);

  useEffect(() => {
    fetchAllWebsiteCategories();
  }, [fetchAllWebsiteCategories]);

  const handleOrderPackages = () => {
    setLoader(true);
    dispatch(
      orderCategoryAction(
        {
          categories: updatedData
        },
        (res) => {
          toast.success(res.data.message);
          setToggleOrderPackages(false);
          fetchAllWebsiteCategories();
          setUpdatedData([]);
          setLoader(false);
        },
        (err) => {
          toast.error(err);
        }
      )
    );
  };

  return (
    <div>
      <div className={styles.actionBox__search}>
        <SearchBox
          placeholder="Search by name"
          onChange={(e) => {
            setSearchValue(e);
            setPage(1);
          }}
          searchValue={searchValue}
        />
        <div className={styles.actionBox__btn}>
          <Button theme="secondary" size="plain">
            <div>
              {Object.keys(filteredData).length !== 0 ? (
                <div
                  className={styles.filterBtn}
                  aria-hidden
                  onClick={() => {
                    setFilteredData([]);
                    setOpenFilter(true);
                  }}
                >
                  <p>Filter</p> &nbsp;
                  <img src={Cancel} alt="cancel" />
                </div>
              ) : (
                <div className={styles.filterBtn} onClick={() => setOpenFilter(true)} aria-hidden>
                  <p>Filter</p> &nbsp;
                  <img src={FilterIcon} alt="filter" />
                </div>
              )}
            </div>
          </Button>
          <Button
            theme="primary"
            size="sm"
            onClick={() => navigate('/website-management/home-package/create')}
          >
            <img src={Plus} alt="add" />
            &nbsp; Curate a package
          </Button>
          <Button
            theme="orange"
            size="sm"
            onClick={
              updatedData?.length
                ? () => handleOrderPackages()
                : () => setToggleOrderPackages(!toggleOrderPackages)
            }
            // onClick={() => handleOrderPackages()}
            loading={loader}
          >
            {updatedData?.length ? 'Save Order' : 'Order Packages'}
          </Button>
        </div>
      </div>

      <ResponsiveHomePackages
        loading={loading}
        allCategories={allCategories}
        setUpdatedData={setUpdatedData}
        loader={loader}
        setLoader={setLoader}
        fetchAllWebsiteCategories={fetchAllWebsiteCategories}
        toggleOrderPackages={toggleOrderPackages}
      />

      {!allCategories?.packageCategories?.length && !loading && <EmptyState />}
      {allCategories?.packageCategories?.length && !loading ? (
        <Pagination
          currentPage={page}
          changeCurrentPage={handlePageChange}
          forcePage={page - 1}
          handlePageInput={(e) => setPage(e)}
          totalPage={getTotalPages(allCategories?.total, 50)}
        />
      ) : null}
      {openFilter && (
        <FilterHomePackages
          isOpen={openFilter}
          isClose={() => setOpenFilter(false)}
          setFilteredData={setFilteredData}
          setPage={setPage}
        />
      )}
    </div>
  );
};

HomePagePackages.propTypes = {
  setCount: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func
};

export default HomePagePackages;
